import React, { useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { PRODUCTS } from "../graphql/queries/product";
import {
  Button,
  Divider,
  Dropdown,
  Image,
  Menu,
  Modal,
  notification,
  Popconfirm,
  Table,
  Tooltip,
} from "antd";
import moment from "moment";
import _ from "lodash";
import {
  CaretDownOutlined,
  CopyOutlined,
  DeleteOutlined,
  // EditFilled,
  EyeOutlined,
} from "@ant-design/icons";
import { useSelector } from "react-redux";
import { history } from "../history";
import { DELETE } from "../graphql/mutation/product/delete";
import { CLOUDFRONT_URL } from "../config";
import ModalPreviewimage from "./ModalPreviewimage";
const _targetBlank = {
  target: "_blank",
  rel: "noopener noreferrer",
};

const ProductsList = (props) => {
  const { currentSite, sites, filter, onChange } = props;
  const currentUser = useSelector((state) => state.app.currentUser);
  var seller = currentUser.roles.map((role) => role.name).includes("Seller");
  if (seller) {
    filter.site_ids = currentSite ? [currentSite.id] : ["1"];
  }
  const { data, loading, refetch } = useQuery(PRODUCTS, {
    fetchPolicy: "network-only",
    variables: { filter: filter },
  });
  // console.log(filter);
  // console.log(filter);
  // const [filterProduct, setFilterProduct] = useState(filter);
  const [deletePr] = useMutation(DELETE);
  // const [page, setPage] = useState(1);
  const [previewImage, setPreviewImage] = useState(false);

  const allowProductAction =
    currentUser.roles.map((role) => role.name).includes("Administrator") ||
    (currentSite && currentSite.type !== "offline");
  const allowCloneProduct =
    currentUser.roles.map((role) => role.name).includes("Seller") &&
    currentSite &&
    currentSite.type !== "offline";
  const deleteProduct = (id) => {
    deletePr({ variables: { product_id: id, isDelOnStore: true } })
      .then((res) => {
        notification.success({ message: "Delete product success" });
        refetch();
      })
      .catch((err) => notification.error({ message: err.message }));
  };

  const columns = [
    {
      title: "ID",
      width: 100,
      dataIndex: 'id',
      render: (id)=> (
        <div>
          <Button title={'Tap to copy id '+ id} onClick={()=> {
            const coppy = navigator.clipboard.writeText(id);
            if(coppy){
              notification.success({
                message: "Successfully copied product ID " + id,
              });
            }
          }} type="text">{id}</Button>
        </div>
      )
    },
    {
      title: "Origin ID",
      width: 150,
      render: (_, { product_stores }) => product_stores[0].origin_id,
    },
    {
      title: "Title",
      dataIndex: "title",
      width: 300,
      render: (
        title,
        { id, product_stores, images, sku, variants, mockups }
      ) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          {/* <Image.PreviewGroup>
            {mockups && mockups[0].image ? (
              mockups.map((mockup, index) => (
                <Image
                  hidden={index !== 0}
                  key={index}
                  style={{
                    backgroundColor: "#f5f5f5",
                    objectFit: "cover",
                    height: 120,
                  }}
                  preview={{
                    src: `${CLOUDFRONT_URL}/autoxauto/${mockup.image}`,
                  }}
                  src={`${CLOUDFRONT_URL}/100/${mockup.image}`}
                  fallback={`/no-preview.jpg`}
                />
              ))
            ) : (
              <Image
                style={{
                  backgroundColor: "#f5f5f5",
                  objectFit: "contain",
                  height: 120,
                  width: 100,
                }}
                preview={{
                  src: `${CLOUDFRONT_URL}/autoxauto/`,
                }}
                src={`${CLOUDFRONT_URL}/100/`}
                fallback={`/no-preview.jpg`}
              />
            )}
          </Image.PreviewGroup> */}
          <div className="cp-image">
            <Image
              style={{
                backgroundColor: "#f5f5f5",
                // objectFit: `${mockups ? "cover" : "contain"}`,
                objectFit: "contain",
                height: 120,
                width: 100,
              }}
              preview={false}
              // preview={{
              //   src: `${CLOUDFRONT_URL}/autoxauto/${mockup.image}`,
              // }}
              src={`${CLOUDFRONT_URL}/100/${mockups ? mockups[0].image : null}`}
              fallback={`/no-preview.jpg`}
            />
            {mockups ? (
              <div
                style={{ height: 120 }}
                className="cp-prewview-image"
                onClick={() => {
                  setPreviewImage(mockups.map((item) => item.image));
                }}
              >
                <EyeOutlined style={{ marginRight: 5 }} />
                Preview
              </div>
            ) : null}
          </div>
          <div style={{ marginLeft: 10 }}>
            {title} <br />
            {variants && <em style={{ color: "#8D8D8D" }}>{sku}</em>}
          </div>
        </div>
      ),
    },
    {
      title: "Sites",
      dataIndex: "product_stores",
      width: 250,
      render: (product_stores) => {
        if (currentUser.roles.map((role) => role.name).includes("Seller")) {
          return (
            <a
              href={
                product_stores?.find(
                  (item) => item.site_id === filter.site_ids?.[0]
                )?.permalink
              }
              {..._targetBlank}
            >
              {
                sites.find((item) => item.id === product_stores[0]?.site_id)?.title ?  sites.find((item) => item.id === product_stores[0]?.site_id)?.title : "See here"
              }
              {/* {" - "}
              {
                sites.find((item) => item.id === product_stores[0]?.site_id)
                  ?.domain
              } */}
            </a>
          );
        } else {
          return (
            <Dropdown
              overlay={
                <div>
                  {product_stores.length >= 2 && (
                    <Menu>
                      {product_stores.map((item) => (
                        <Menu.Item>
                          <a
                            {..._targetBlank}
                            rel="noopener noreferrer"
                            href={item.permalink}
                          >
                            {sites.find((i) => i.id === item.site_id)?.title}
                            {/* {" - "}
                            {sites.find((i) => i.id === item.site_id)?.domain} */}
                          </a>
                        </Menu.Item>
                      ))}
                    </Menu>
                  )}
                </div>
              }
            >
              <a
                className="ant-dropdown-link"
                href={product_stores[0]?.permalink}
                {..._targetBlank}
              >
                {
                  sites.find((item) => item.id === product_stores[0]?.site_id)?.title ?  sites.find((item) => item.id === product_stores[0]?.site_id)?.title : "See here"
                }
                {/* {" - "}
                {
                  sites.find((item) => item.id === product_stores[0]?.site_id)
                    ?.domain
                } */}
                {product_stores.length > 1 ? <CaretDownOutlined /> : null}
              </a>
            </Dropdown>
          );
        }
      },
    },

    {
      title: "Created Date",
      width: 150,
      dataIndex: "created_at",
      render: (created_at) => moment(created_at).format("MMM DD, YYYY"),
    },
    {
      title: "Actions",
      width: 180,
      dataIndex: "id",
      key: "actions",
      align: "right",
      render: (id, product) => (
        <>
          {allowCloneProduct && (
            <>
              <Tooltip title="Clone product">
                <Button
                  size="small"
                  onClick={(e) => {
                    e.preventDefault();
                    const product_type_ids = [
                      ...new Set(
                        product.variants.map((el) => el.product_type_id)
                      ),
                    ];
                    history.push(
                      `/products/clone/${id}/${product_type_ids.join(",")}`
                    );
                  }}
                >
                  <CopyOutlined />
                </Button>
              </Tooltip>
              <Divider type="vertical" />
            </>
          )}
          {allowProductAction && (
            <Tooltip title="Delete product">
              <Popconfirm
                title="This action will delete the product on store also. Are you sure?"
                onConfirm={() => deleteProduct(id)}
                okText="Yes"
                cancelText="No"
              >
                <Button size="small">
                  <DeleteOutlined style={{ color: "red" }} />
                </Button>
              </Popconfirm>
            </Tooltip>
          )}
        </>
      ),
    },
  ];
  const pagination = {
    pageSize: filter.limit,
    total: data ? data.products.count : 0,
    showTotal: (total, range) => `${range} of ${total}`,
    onChange: (page, pageSize) => {
      // setFilterProduct({ ...filterProduct, offset: (page - 1)*pageSize });
      onChange({
        ...filter,
        offset: (page - 1) * pageSize,
      });

      //   this.setState(
      //     {
      //       filter: {
      //         ...filter,
      //         offset: (page - 1) * pageSize,
      //       },
      //     },
      //     () => this.loadProducts()
      //   );
    },
  };
  const widthColumn = _.sum(columns.map((c) => c.width));
  return (
    <div>
      <Table
        columns={columns}
        dataSource={data ? data.products.hits : []}
        // rowSelection={rowSelection}
        pagination={pagination}
        rowKey={({ id }) => id}
        loading={loading}
        scroll={{ x: widthColumn }}
      />
      {previewImage && (
        <Modal
          width={800}
          title="Preview Image"
          visible={previewImage ? true : false}
          onCancel={() => setPreviewImage(false)}
          footer={false}
        >
          <ModalPreviewimage keys={previewImage} />
        </Modal>
      )}
    </div>
  );
};

export default ProductsList;
