import React from "react";
import {
  DashboardOutlined,
  UserOutlined,
  GlobalOutlined,
  SkinOutlined,
  ShoppingOutlined,
  SettingOutlined,
  UnorderedListOutlined,
  DatabaseOutlined,
  FileDoneOutlined,
  ApiOutlined,
  PieChartOutlined,
  ExperimentFilled
} from "@ant-design/icons";
export const adminMenu = [
  // {
  //   title: "Dashboard",
  //   router: "/dashboard",
  //   icon: <DashboardOutlined />,
  //   role: ["Administrator", "Seller", "Supplier"],
  // },
  {
    title: "Users",
    router: "/admin/users-manager",
    icon: <UserOutlined />,
    role: ["Administrator"],
  },
  {
    title: "Connect Sites",
    router: "/sites",
    icon: <GlobalOutlined />,
    role: ["Administrator", "Seller"],
  },
  {
    title: "Products",
    router: "/products",
    icon: <SkinOutlined />,
    role: ["Administrator", "Seller"],
    child: [
      {
        title: "Products",
        router: "/products",
      },
      {
        title: "Pushing Products",
        router: "/products/pushing",
      },
    ],
  },
  {
    title: "Orders",
    router: "/orders",
    icon: <ShoppingOutlined />,
    role: ["Seller"],
  },
  {
    title: "Orders",
    router: "/orders",
    icon: <ShoppingOutlined />,
    role: ["Administrator"],
    child: [
      {
        title: "Orders",
        router: "/orders",
      },
      {
        title: "Exported CSV ",
        router: "/orders/export-orders",
        role: ["Administrator"],
      },
    ],
  },
  {
    title: "Artworks",
    router: "/artworks",
    icon: <PieChartOutlined />,
    role: ["Administrator", "Seller"],
    child: [
      {
        title: "Artworks",
        router: "/artworks",
      },
      {
        title: "Categories",
        router: "/artworks/categories",
      },
    ],
  },
  {
    title: "Cliparts",
    router: "/cliparts",
    icon: <ApiOutlined />,
    role: ["Administrator", "Seller"],
  },
  {
    title: "AI",
    router: "/ai/image-generation",
    icon: <ExperimentFilled />,
    role: ["Administrator", "Seller","Designs"],
    child: [
      {
        title: "Server MJD",
        router: "/ai/server-mjd",
      },
      {
        title: "Server KLI",
        router: "/ai/server-kli",
      },
      {
        title: "Server GPT",
        router: "/ai/server-gpt",
      },
    ]
  },
  {
    title: "App Settings",
    icon: <SettingOutlined />,
    role: ["Administrator"],
    child: [
      {
        title: "Product Types",
        router: "/admin/product-types",
        icon: <SkinOutlined />,
      },
      {
        title: "Categories",
        router: "/admin/categories",
        icon: <UnorderedListOutlined />,
      },
      {
        title: "Export templates",
        router: "/export-templates",
        icon: <DatabaseOutlined />,
      },
    ],
  },
  {
    title: "Export templates",
    router: "/export-templates",
    icon: <DatabaseOutlined />,
    role: ["Supplier"],
  },
];
