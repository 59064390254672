import React from "react";
import {
  message,
  Table,
  Button,
  Popconfirm,
  Select,
  Divider,
  Row,
  Col,
  Tooltip,
} from "antd";
import gql from "../../api/gql";
import _ from "lodash";
import styled from "styled-components";
import { history } from "../../history";
import { connect } from "react-redux";
import CategoryToolbar from "./CategoryToolbar";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
const Container = styled.div`
  .ant-row-flex {
    margin-bottom: 20px;
  }
  @media only screen and (max-width: 540px) {
    .ant-row-flex {
      margin-bottom: 10px;
    }
  }
`;

class Category extends React.Component {
  state = {
    categories: [],
    loading: false,
    selectedCategory: null,
    filter: {
      status: true,
      offset: 0,
      limit: 10,
      ...this.props.filter,
    },
    total: 0,
    page: 1,
  };

  componentDidMount() {
    this.getCategories();
  }
  componentDidUpdate(prevProps) {
    if (prevProps.filter !== this.props.filter) {
      this.setState(
        {
          page: 1,
          filter: { ...this.props.filter, offset: 0, limit: 10 },
        },
        () => this.getListUser()
      );
    }
  }
  getCategories = () => {
    const query = `query categories($filter: CategoryFilter){
			categories(filter: $filter){
				count
				hits{
					id title status created_at updated_at totalProductType creater{
						id last_name first_name phone
					}
				}
			}
		}`;
    this.setState({ loading: true });
    gql
      .request(query, { filter: this.state.filter })
      .then((res) => {
        this.setState({
          categories: res.categories.hits || [],
          total: res.categories.count,
          loading: false,
        });
      })
      .catch((err) => {
        message["error"](_.get(err, "[0].message"));
        this.setState({ loading: false });
      });
  };

  DeleteCategory = (id) => {
    const mutation = `
		mutation deleteCategory($id: String!){
			deleteCategory(id: $id)}`;
    gql
      .request(mutation, { id })
      .then((res) => {
        this.setState({ loading: false });
        if (res.deleteCategory) {
          message["success"]("Delete success!");
          this.getCategories();
        } else {
          message["error"](_.get(res.errors, "[0].message"));
        }
      })
      .catch((err) => {
        this.setState({ loading: false });
        message["error"](_.get(err, "[0].message"));
      });
  };

  handleEditUpdate = () => {
    this.setState({ showUpdate: true });
  };

  handleChangeStatus = async (value) => {
    await this.setState({
      filter: {
        ...this.state.filter,
        status: value.key === "active" ? true : false,
      },
    });
    this.getCategories();
  };
  render() {
    const { categories, loading, total, filter, page } = this.state;
    const isSupporter = this.props.currentUser.roles.find((el) =>
      ["Supporter"].includes(el.name)
    );
    const columns = [
      {
        title: "Categories",
        width: 150,
        dataIndex: "title",
        key: "title",
      },
      {
        title: "Product Type",
        width: 120,
        dataIndex: "totalProductType",
        align: "center",
        key: "totalProductType",
      },
      !isSupporter
        ? {
            title: "Action",
            width: 150,
            key: "action",
            align: "right",
            dataIndex: "id",
            render: (id, record) => (
              <Container>
                <Tooltip title="Edit category">
                  <Button
                    size="small"
                    onClick={() => history.push(`/admin/category/edit/${id}`)}
                  >
                    <EditOutlined theme="twoTone" />
                  </Button>
                </Tooltip>
                <Divider type="vertical" />
                <Tooltip title="Delete category">
                  <Popconfirm
                    title="Are you sure delete this category?"
                    placement="topRight"
                    onConfirm={() => this.DeleteCategory(record.id)}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Button size="small">
                      <DeleteOutlined style={{ color: "red" }} />
                    </Button>
                  </Popconfirm>
                </Tooltip>
              </Container>
            ),
          }
        : {},
    ];
    const tableWidth = _.sum(columns.map((c) => c.width));
    const pagination = {
      pageSize: filter.limit,
      total: total,
      curent: page,
      showTotal: (total, range) => `${range} of ${total}`,
      onChange: (page, pageSize) => {
        this.setState(
          {
            page: page,
            filter: {
              ...filter,
              offset: (page - 1) * pageSize,
            },
          },
          () => this.getCategories()
        );
      },
    };
    return (
      <Container>
        <Row type="flex" style={{ width: "calc(100%)", marginBottom: 15 }}>
          <Col
            span={24}
            lg={20}
            style={{ display: "flex", alignItems: "center" }}
          >
            <Select
              labelInValue
              defaultValue={{ key: "active" }}
              style={{ width: 180 }}
              onChange={this.handleChangeStatus}
            >
              <Select.Option value="active">Active</Select.Option>
              <Select.Option value="inactive">Inactive</Select.Option>
            </Select>
          </Col>
          <Col span={0} lg={4} style={{ textAlign: "right" }}>
            <CategoryToolbar />
          </Col>
        </Row>
        <Table
          // rowKey={(row, index) => index}
          rowKey={"id"}
          dataSource={categories}
          columns={columns}
          loading={loading}
          pagination={pagination}
          scroll={{ x: tableWidth }}
        />
      </Container>
    );
  }
}

export default connect((state) => ({
  currentUser: state.app.currentUser,
}))(Category);
